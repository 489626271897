import React from 'react'

import styles from '../sass/logo.scss'

import logoImg from '../images/logo.png'

export function Logo() {
    return <div className={styles.logo} style={{
        background: `url(${logoImg}) no-repeat`, backgroundSize: 'cover', backgroundPosition: 'center'
    }}>
    </div>;
}

export default Logo;