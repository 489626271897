const translations = {
    en: {
        dashboard: 'Dashboard',
        myObjects: 'My objects',
        myActions: 'My actions',
        overview: 'Overview',
        chooseLanguage: 'Choose language',
        sections: 'Sections',
        turbineBlade: 'Blade',
        loading: 'Loading...',
        properties: 'Properties',
        comment: 'Comment',
        save: 'Save',
        delete: 'Delete',
        name: 'name',
        add: 'Add',
        update: 'Update',
        cancel: 'Cancel',
        administrationUnit: 'Administration unit',
        farm: 'Farm',
        status: 'Status',
        all: 'All',
        downloadReport: 'Download report',
        addPhotos: 'Add photos',
        detectedIssues: 'Detected issues',
        bladeSide: 'Blade side',
        size: 'Size',
        distanceFromRoot: 'Distance from root',
        setFilters: 'Set filters',
        changeOrthoimage: 'Change ortoimage',
        manage: 'Manage',
        satellite: 'Satellite',
        map: 'Map',
        refPhotoChange: 'Change the reference photo',
        browse: 'Browse',
        managing: 'Managing',
        flyDate: 'Flyover date',
        distance: 'Distance',
        area: 'Area',
        downloadDxf: 'Download dxf',
        issueType: 'Issue type',
        addNewFacade: 'Add new subobject',
        facadeName: 'Subobject name',
        isRoof: 'Is roof?',
        editFacade: 'Edit subobject',
        editBuilding: 'Edit object',
        buildingName: 'Object name',
        managerName: 'Manager name',
        managerLastName: 'Manager lastname',
        addBuilding: 'Add object',
        addOrganization: 'Add organization',
        organizationName: 'Organization name',
        addRole: 'Add role',
        roleName: 'Role name',
        addAccessGroup: 'Add access group',
        groupName: 'Group name',
        addObjectsGroup: 'Add objects group',
        accessGroup: 'Access group',
        editRole: 'Edit role',
        actionType: 'Action type',
        allowed: 'Allowed',
        revokeAccess: 'Revoke access',
        editAccessGroup: 'Edit access group',
        editOrganization: 'Edit organization',
        editIssuesCategories: 'Edit issues categories',
        endDrawing: 'End drawing',
        endDrawingAndCloseVector: 'End drawing and close polygon',
        editObjectsGroup: 'Edit objects group',
        company: 'Company',
        edit: 'Edit',
        roles: 'Roles',
        accessGroups: 'Access groups',
        organizations: 'Organizations',
        buildings: 'Objects',
        facades: 'Subobjects',
        issues: 'Issues',
        dates: 'Dates',
        deletingPhoto: 'Delete photo',
        deletingPhotos: 'Delete photos',
        doYouWantToDeletePhoto: 'Are you sure you want to DELETE this photo?',
        doYouWantToDeletePhotos: 'Are you sure you want to DELETE photos for this date?',
        distanceMeasure: 'Distance measure',
        measuredDistance: 'Measured distance',
        measurePointBeyondOrthoImageArea: 'Measure point beyond orthoimage area',
        resendActivation: 'Resend activation link',
        updatePhotoPoints: 'Update photo points',
        photoPointsUpdated: '{0} photo points updated, {1} photo points omitted!',
        photoPointUpdateError: 'Photo point update error',
        logOut: 'Sign out',
        newMeasure: 'New measure'
    },
    pl: {
        uploadGeojson: 'Załaduj plik geojson',
        deleteGeojson: 'Usuń plik geojson',
        dashboard: 'Tablica przeglądowa',
        myObjects: 'Grupy farm',
        myActions: 'Moje akcje',
        overview: 'Przegląd',
        chooseLanguage: 'Wybierz język',
        sections: 'Sekcje',
        turbineBlade: 'Łopata',
        loading: 'Trwa ładowanie...',
        properties: 'Właściwości',
        comment: 'Komentarz',
        save: 'Zapisz',
        delete: 'Usuń',
        name: 'Nazwa',
        add: 'Dodaj',
        update: 'Aktualizuj',
        cancel: 'Anuluj',
        administrationUnit: 'Województwo',
        farm: 'Farma',
        status: 'Status',
        all: 'Wszystkie',
        downloadReport: 'Pobierz raport',
        addPhotos: 'Dodaj zdjęcia',
        detectedIssues: 'Wykryte uszkodzenia',
        bladeSide: 'Strona łopaty',
        size: 'Rozmiar',
        distanceFromRoot: 'Odległość od piasty',
        setFilters: 'Ustaw filtry',
        changeOrthoimage: 'Zmień ortoobraz',
        manage: 'Zarządzaj',
        satellite: 'Satelita',
        map: 'Mapa',
        refPhotoChange: 'Zmień zdjęcie poglądowe',
        browse: 'Przeglądaj',
        managing: 'Zarządzający',
        flyDate: 'Data nalotu',
        distance: 'Długość',
        area: 'Powierzchnia',
        downloadDxf: 'Pobierz dxf',
        issueType: 'Rodzaj uszkodzenia',
        addNewFacade: 'Dodaj nową sekcję',
        facadeName: 'Nazwa sekcji',
        isRoof: 'Czy dach?',
        editFacade: 'Edytuj sekcję',
        editBuilding: 'Edytuj farmę',
        buildingName: 'Nazwa farmy',
        managerName: 'Imię zarządzającego',
        managerLastName: 'Nazwisko zarządzającego',
        addBuilding: 'Dodaj farmę',
        addOrganization: 'Dodaj organizację',
        organizationName: 'Nazwa organizacji',
        addRole: 'Dodaj rolę',
        roleName: 'Nazwa roli',
        addAccessGroup: 'Dodaj grupę dostępu',
        groupName: 'Nazwa grupy',
        addObjectsGroup: 'Dodaj grupę farm',
        accessGroup: 'Grupa dostępu',
        editRole: 'Edytuj rolę',
        actionType: 'Typ akcji',
        allowed: 'Dozwolony',
        revokeAccess: 'Odbierz dostęp',
        editAccessGroup: 'Edytuj grupę dostępu',
        editOrganization: 'Edytuj organizację',
        editIssuesCategories: 'Edytuj kategorie uszkodzeń',
        endDrawing: 'Zakończ rysowanie',
        endDrawingAndCloseVector: 'Zakończ rysowanie i zamknij poligon',
        editObjectsGroup: 'Edytuj grupę farm',
        company: 'Firma',
        edit: 'Edytuj',
        roles: 'Role',
        accessGroups: 'Grupy dostępów',
        organizations: 'Organizacje',
        buildings: 'Farmy',
        facades: 'Sekcje',
        issues: 'Uszkodzenia',
        dates: 'Daty',
        deletingPhoto: 'Usuwanie zdjęcia',
        deletingPhotos: 'Usuwanie zdjęc',
        doYouWantToDeletePhoto: 'Czy na pewno chcesz usunąć to zdjęcie?',
        doYouWantToDeletePhotos: 'Czy na pewno chcesz usunąć zdjęcia dla tej daty?',
        distanceMeasure: 'Pomiar odległości',
        measuredDistance: 'Pomierzona odległość',
        measurePointBeyondOrthoImageArea: 'Punkt pomiaru poza obszarem ortoobrazu',
        resendActivation: 'Prześlij ponownie link aktywacji',
        updatePhotoPoints: 'Aktualizuj punkty zdjęć',
        photoPointsUpdated: '{0} punktów zdjęć zaktualizowanych, {1} punktów zdjęć pominiętych!',
        photoPointUpdateError: 'Błąd aktualizacji zdjęć',
        logOut: 'Wyloguj',
        newMeasure: 'Nowy pomiar'
    },
    de: {
        dashboard: 'Instrumententafel',
        myObjects: 'Meine Objekte',
        myActions: 'Meine Aktien',
        overview: 'Überblick',
        chooseLanguage: 'Sprache wählen',
        sections: 'Abschnitte',
        turbineBlade: 'Turbinenschaufel',
        loading: 'Wird geladen...',
        properties: 'Eigenschaften',
        comment: 'Kommentar',
        save: 'Sparen',
        delete: 'Löschen',
        name: 'Name',
        administrationUnit: 'Verwaltungseinheit',
        farm: 'Windpark',
        status: 'Status',
        all: 'Alles',
        add: 'Add',
        update: 'Update',
        downloadReport: 'Laden Sie den Bericht herunter',
        addPhotos: 'Fotos hinzufügen',
        detectedIssues: 'Festgestellter Schaden',
        bladeSide: 'Blade side',
        size: 'Größe',
        distanceFromRoot: 'Abstand von der Nabe',
        setFilters: 'Set filters',
        changeOrthoimage: 'Orthoimage ändern',
        manage: 'Verwalten',
        satellite: 'Satellit',
        map: 'Map',
        refPhotoChange: 'Ändern sie das referenzfoto',
        browse: 'Durchsuche',
        managing: 'Verwaltung',
        flyDate: 'Raid-Datum', //change to something less sinister
        distance: 'Länge',
        area: 'Oberfläche',
        downloadDxf: 'download Dxf',
        issueType: 'Issue type',
        addNewFacade: 'Add new subobject',
        facadeName: 'Subobject name',
        isRoof: 'Is roof?',
        editFacade: 'Edit subobject',
        editBuilding: 'Edit object',
        buildingName: 'Object name',
        managerName: 'Manager name',
        managerLastName: 'Manager lastname',
        addBuilding: 'Add object',
        addOrganization: 'Add organization',
        organizationName: 'Organization name',
        cancel: 'Cancel',
        addRole: 'Add role',
        roleName: 'Role name',
        addAccessGroup: 'Dodaj grupę dostępu',
        groupName: 'Group name',
        addObjectsGroup: 'Add objects group',
        accessGroup: 'Access group',
        editRole: 'Edit role',
        actionType: 'Action type',
        allowed: 'Allowed',
        revokeAccess: 'Odbierz dostęp',
        editAccessGroup: 'Edytuj grupę dostępu',
        editOrganization: 'Edit organization',
        editIssuesCategories: 'Edit issues categories',
        endDrawing: 'End drawing',
        endDrawingAndCloseVector: 'End drawing and close polygon',
        editObjectsGroup: 'Edit objects group',
        company: 'Company',
        edit: 'Edit',
        roles: 'Roles',
        accessGroups: 'Access groups',
        organizations: 'Organizations',
        buildings: 'Objects',
        facades: 'Subobjects',
        issues: 'Issues',
        dates: 'Dates',
        deletingPhoto: 'Delete photo',
        deletingPhotos: 'Delete photos',
        doYouWantToDeletePhoto: 'Are you sure you want to DELETE this photo?',
        doYouWantToDeletePhotos: 'Are you sure you want to DELETE photos for this date?',
        distanceMeasure: 'Distance measure',
        measuredDistance: 'Measured distance',
        measurePointBeyondOrthoImageArea: 'Measure point beyond orthoimage area',
        resendActivation: 'Prześlij ponownie link aktywacji',
        updatePhotoPoints: 'Update photo points',
        photoPointsUpdated: '{0} {1} Punkty zdjęć zaktualizowane!',
        photoPointUpdateError: 'Błąd aktualizacji zdjęć',
        logOut: 'Sign out',
        newMeasure: 'New measure'
    }
};

export function getTranslation(texts, text) {
    if (texts.locale && texts.translations) {
        return texts.translations[texts.locale][text];
    }

    return '';
}

export default translations;