// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T5H1P0zmsPEuWJ3Gkwi5jA\\=\\={width:100%;text-align:right}.YuC8QNA19in9umS0lHwUSQ\\=\\={color:red}", "",{"version":3,"sources":["webpack://Skysnap.WebPortal.Client/Login/sass/loginForm.scss"],"names":[],"mappings":"AAAA,4BACI,UAAA,CACA,gBAAA,CAGJ,4BACI,SAAA","sourcesContent":[".loginBtnContainer {\r\n    width: 100%;\r\n    text-align: right;\r\n}\r\n\r\n.errorText {\r\n    color: red;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginBtnContainer": "T5H1P0zmsPEuWJ3Gkwi5jA==",
	"errorText": "YuC8QNA19in9umS0lHwUSQ=="
};
export default ___CSS_LOADER_EXPORT___;
