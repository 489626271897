const translations = {
    en: {
        welcomeBack: 'Welcome back',
        pleaseSignIn: 'Please sign in to your account',
        noAccount: 'No account',
        signUpNow: 'Sign up now',
        email: 'Email',
        password: 'Password',
        emailHere: 'Email here...',
        passwordHere: 'Password here...',
        keepMeSignedIn: 'Keep me signed in',
        recoverPassword: 'Recover password',
        logIn: 'login to Dashboard',
        incorrectLoginData: 'Incorect login data',
        loading: 'Loading...',
        activateAccount: 'Activate your account',
        repeatPassword: 'Repeat password',
        activate: 'Activate'
    },
    pl: {
        welcomeBack: 'Witaj ponownie',
        pleaseSignIn: 'Zaloguj sie na swoje konto',
        noAccount: 'Brak konta',
        signUpNow: 'Zarejestruj się',
        email: 'Email',
        password: 'Hasło',
        emailHere: 'Email tutaj...',
        passwordHere: 'Hasło tutaj...',
        keepMeSignedIn: 'Chcę pozostać zalogowany',
        recoverPassword: 'Odzyskaj hasło',
        logIn: 'Zaloguj się',
        incorrectLoginData: 'Nieprawidłowe dane logowania',
        loading: 'Ładowanie...',
        activateAccount: 'Aktywuj swoje konto',
        repeatPassword: 'Powtórz hasło',
        activate: 'Aktywuj'
    },
    de: {
        welcomeBack: 'Willkommen zurück',
        pleaseSignIn: 'Bitte melden Sie sich in Ihrem Konto an',
        noAccount: 'Kein Account',
        signUpNow: 'Jetzt registrierien',
        email: 'Email',
        password: 'Passwort',
        emailHere: 'Email hier...',
        passwordHere: 'Passwort hier...',
        keepMeSignedIn: 'Halten Sie mich angemeldet',
        recoverPassword: 'Passwort wiederherstellen',
        logIn: 'Anmeldung',
        incorrectLoginData: 'Falsche Anmeldedaten',
        loading: 'Wird geladen...',
        activateAccount: 'Activate your account',
        repeatPassword: 'Repeat password',
        activate: 'Activate'
    }
};

export function getTranslation(texts, text) {
    if (texts.locale && texts.translations) {
        return texts.translations[texts.locale][text];
    }

    return '';
}

export default translations;