// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gA5nr4tOEy\\+94jNz-o\\+9SQ\\=\\={z-index:-1;position:absolute;top:0;left:0;right:0;width:100vw;object-fit:cover;object-position:bottom;height:100vh}", "",{"version":3,"sources":["webpack://Skysnap.WebPortal.Client/Login/sass/backgroundVideo.scss"],"names":[],"mappings":"AAAA,8BACI,UAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,WAAA,CACA,gBAAA,CACA,sBAAA,CACA,YAAA","sourcesContent":[".backgroundVideo {\r\n    z-index: -1;\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    width: 100vw;\r\n    object-fit: cover;\r\n    object-position: bottom;\r\n    height: 100vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundVideo": "gA5nr4tOEy+94jNz-o+9SQ=="
};
export default ___CSS_LOADER_EXPORT___;
