export const THEME_SETTINGS_KEY = 'themeSettings';
export const TEXT_SIZE = {
    A: 'A',
    AP: 'A+',
    APP: 'A++',
}

/** 
 * Get the theme settings based on the contrast
 */
const getThemeSettings = () => {
    const settings = window.localStorage.getItem(THEME_SETTINGS_KEY);
  
    if (settings) {
        return JSON.parse(settings);
    }

    const themeSettings = {
        contrastMode: false,
        textSize: TEXT_SIZE.A,
    };

    window.localStorage.setItem(THEME_SETTINGS_KEY, JSON.stringify(themeSettings));

    return themeSettings;
};

const setThemeSettings = ({contrastMode, textSize}) => {
    const settingsString = window.localStorage.getItem(THEME_SETTINGS_KEY);
    const settings = settingsString ? JSON.parse(settingsString) : {
        contrastMode: false,
        textSize: TEXT_SIZE.A,
    };
    const newSettings = {
        contrastMode: contrastMode ?? settings.contrastMode,
        textSize: textSize ?? settings.textSize,
    };
    window.localStorage.setItem(THEME_SETTINGS_KEY, JSON.stringify(newSettings));
};

const themeSettings = {
    get: getThemeSettings,
    set: setThemeSettings,
};

export default themeSettings;