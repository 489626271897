import fetch from 'cross-fetch';

const apiLink = `../api/User`;

export const activateAccount = (token, newPassword) => {
    return fetch(`${apiLink}/Activate`, {
        method: 'POST',
        body: JSON.stringify({
            token: token,
            newPassword: newPassword
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
}