export function supportedLanguageList() {
    return [
        {
            locale: 'pl',
            name: 'Polski'
        },
        {
            locale: 'de',
            name: 'Deutsch'
        },
        {
            locale: 'en',
            name: 'English'
        }
    ];
}