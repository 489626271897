// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cgk62ClOxVHYnvvjiVczlA\\=\\={height:200px;width:100%}", "",{"version":3,"sources":["webpack://Skysnap.WebPortal.Client/Login/sass/logo.scss"],"names":[],"mappings":"AAAA,4BACI,YAAA,CACA,UAAA","sourcesContent":[".logo {\r\n    height: 200px;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "cgk62ClOxVHYnvvjiVczlA=="
};
export default ___CSS_LOADER_EXPORT___;
