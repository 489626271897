import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { activateAccount } from '../api';

export const activateUserAccount = createAsyncThunk(
    'account/activateUserAccount',
    async ({ token, newPassword }, { thunkApi }) => {
        let response = await activateAccount(token, newPassword);
        return response;
    }
);

export const accountReducer = createSlice({
    name: 'account',
    initialState: {
        activating: false
    },
    reducers: {
    },
    extraReducers: {
        [activateUserAccount.pending]: (state, action) => {
            state.activating = true;
        },
        [activateUserAccount.fulfilled]: (state, action) => {
            state.activating = false;
        }
    }
});