import React, {useCallback, useEffect, useState} from 'react';
import {Form, Checkbox} from 'semantic-ui-react';

import {unwrapResult} from '@reduxjs/toolkit';

import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';

import {activateUserAccount} from '../reducers/accountReducer';

import styles from '../sass/loginForm.scss';
import commonStylesN from '../sass/common.scss';
import commonStylesC from '../sass/commonContrast.scss';

import { getTranslation } from '../../Portal/translations/translations';

import {changeLanguage} from '../actions/actions';

export const ActivateAccountForm = () => {
    const dispatch = useDispatch();
    const contrastMode = useSelector(state => state.theme.contrastMode);
    const commonStyles = contrastMode ? commonStylesC : commonStylesN;
    const location = useLocation();
    const history = useHistory();

    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    const [token, setToken] = useState('');

    const i18n = useSelector(state => state.i18n);

    const onActivate = useCallback(() => {
        dispatch(
            activateUserAccount({
                token,
                newPassword: password1
            })
        )
            .then(unwrapResult)
            .then(response => {
                if (response.ok) {
                    history.push('../Login');
                }
            });
    }, [dispatch, token, password1, password2]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (token) {
            setToken(token);
        }

    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const locale = params.get('locale');

        if (locale) {
            dispatch(changeLanguage(locale));
        }
    }, []);

    return (
        <React.Fragment>
            <Form>
                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        label={getTranslation(i18n, 'password')}
                        placeholder={getTranslation(i18n, 'passwordHere')}
                        value={password1}
                        type='password'
                        onChange={(e, data) => {
                            setPassword1(e.target.value);
                        }}
                    />
                    <Form.Input
                        fluid
                        label={getTranslation(i18n, 'repeatPassword')}
                        placeholder={getTranslation(i18n, 'passwordHere')}
                        value={password2}
                        type="password"
                        onChange={(e, data) => {
                            setPassword2(e.target.value);
                        }}
                    />
                </Form.Group>
            </Form>
            <div className={commonStyles.invisibleDivider}> </div>{' '}
            <div className={styles.loginBtnContainer}>
                <button className="btn btn-primary btn-lg" onClick={onActivate}>
                    {getTranslation(i18n, 'activate')}
                </button>
            </div>
        </React.Fragment>
    );
};

export default ActivateAccountForm;