import React from 'react'

import styles from '../sass/backgroundVideo.scss'

import loginImg from '../images/login_image.jpg'

export function BackgroundVideo() {
    return <img className={styles.backgroundVideo} src={`/${loginImg}`} alt="Background" />
}

export default BackgroundVideo;