import cx from 'classnames';
import React from 'react';
import { Loader } from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import { getTranslation } from '../../Portal/translations/translations';
import loginStyles from '../sass/login.scss';
import styles from '../sass/loginBox.scss';
import { ActivateAccountForm } from './activateAccountForm';
// import LanguageMenu from './languageMenu';
// import { Logo } from './logo';
import commonStylesN from '../sass/common.scss';
import commonStylesC from '../sass/commonContrast.scss';


export const ActivateAccountBox = () => {
    const activating = useSelector(state => state.account.activating);
    const contrastMode = useSelector(state => state.theme.contrastMode);
    const commonStyles = contrastMode ? commonStylesC : commonStylesN;

    const i18n = useSelector(state => state.i18n);

    return (
        <React.Fragment>
            <LoadingOverlay
                active={activating}
                spinner={
                    <Loader active color="#000" type={'ball-grid-pulse'} />
                }
                text="Trwa aktywacja"
                className={cx(
                    loginStyles.loginBoxContainer
                )}
            >
                <div className={styles.loginBox}>
                    <h4 className="mb-0">
                        <span className="d-block"> {getTranslation(i18n, 'welcomeBack')}, </span>
                        <span> {getTranslation(i18n, 'activateAccount')} </span>
                    </h4>
                    <div className={commonStyles.invisibleDivider}> </div>
                    <ActivateAccountForm />
                    <div className={commonStyles.invisibleDivider}> </div>
                </div>
            </LoadingOverlay>
        </React.Fragment>
    );
};

export default ActivateAccountBox;