// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Um40XbVzpNRK8KdHMzVR8Q\\=\\={float:right}.dsk1-TFpMXkBg21ekFs57g\\=\\={background:unset !important;padding:0 !important}.hPqSPlUcEik8IFULHEXAqw\\=\\={margin:0 auto;height:32px;width:32px}.T3xs1FUIVhfKoY8uj-uTlA\\=\\={display:inline-block}._0zZWgmtSQZxmfZSqA6bWZg\\=\\={height:44px !important;width:44px !important;display:flex;align-items:center;justify-content:center}.UI\\+YaRbAE2noTvk8Ug3pww\\=\\={opacity:.1 !important}.UI\\+YaRbAE2noTvk8Ug3pww\\=\\=:hover{opacity:.2 !important}", "",{"version":3,"sources":["webpack://Skysnap.WebPortal.Client/Login/sass/languageMenu.scss"],"names":[],"mappings":"AAAA,4BACI,WAAA,CAGJ,4BACI,2BAAA,CACA,oBAAA,CAGJ,4BACI,aAAA,CACA,WAAA,CACA,UAAA,CAGJ,4BACI,oBAAA,CAGJ,6BACI,sBAAA,CACA,qBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,6BACI,qBAAA,CAGJ,mCACI,qBAAA","sourcesContent":[".languageMenuContainer {\r\n    float: right;\r\n}\r\n\r\n.flagBtn {\r\n    background: unset !important;\r\n    padding: 0 !important;\r\n}\r\n\r\n.flag {\r\n    margin: 0 auto;\r\n    height: 32px;\r\n    width: 32px;\r\n}\r\n\r\n.flagOuter {\r\n    display: inline-block;\r\n}\r\n\r\n.flagWrapper {\r\n    height: 44px !important;\r\n    width:  44px !important;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.flagBg {\r\n    opacity: 0.1 !important;\r\n}\r\n\r\n.flagBg:hover {\r\n    opacity: 0.2 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageMenuContainer": "Um40XbVzpNRK8KdHMzVR8Q==",
	"flagBtn": "dsk1-TFpMXkBg21ekFs57g==",
	"flag": "hPqSPlUcEik8IFULHEXAqw==",
	"flagOuter": "T3xs1FUIVhfKoY8uj-uTlA==",
	"flagWrapper": "_0zZWgmtSQZxmfZSqA6bWZg==",
	"flagBg": "UI+YaRbAE2noTvk8Ug3pww=="
};
export default ___CSS_LOADER_EXPORT___;
