// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vabpGS1AavzSdWwv58BG\\+A\\=\\={width:80%}.GyymB2\\+6PrFTZinw1vXXuA\\=\\={margin-left:5px}", "",{"version":3,"sources":["webpack://Skysnap.WebPortal.Client/Login/sass/loginBox.scss"],"names":[],"mappings":"AAAA,6BACI,SAAA,CAGJ,6BACI,eAAA","sourcesContent":[".loginBox {\r\n    width: 80%;\r\n}\r\n\r\n.signUpLink {\r\n    margin-left: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginBox": "vabpGS1AavzSdWwv58BG+A==",
	"signUpLink": "GyymB2+6PrFTZinw1vXXuA=="
};
export default ___CSS_LOADER_EXPORT___;
