import { createSlice } from '@reduxjs/toolkit';
import themeSettings from '../../Portal/utils/themeSettings';

const settings = themeSettings.get();

export const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        contrastMode: settings.contrastMode,
        textSize: settings.textSize,
    },
    reducers: {
        setContrastMode: (state, {payload}) => {
            state.contrastMode = payload;
        },
        setTextSize: (state, {payload}) => {
            state.textSize = payload;
        }
    },
});

export const {setContrastMode, setTextSize } = themeSlice.actions;

const themeReducer = themeSlice.reducer;

export default themeReducer;