import cx from 'classnames';
import React from 'react';
import { Loader } from "react-loaders";
import LoadingOverlay from 'react-loading-overlay';
import { connect, useSelector } from 'react-redux';
import commonStylesN from '../sass/common.scss';
import commonStylesC from '../sass/commonContrast.scss';
import loginStyles from '../sass/login.scss';
import styles from '../sass/loginBox.scss';
import { getTranslation } from '../translations/translations';
import LanguageMenu from './languageMenu';
import LoginForm from './loginForm';
import { Logo } from './logo';

export function LoginBox(props) {
    const contrastMode = useSelector(state => state.theme.contrastMode);
    const commonStyles = contrastMode ? commonStylesC : commonStylesN;
    return (
        <LoadingOverlay
            active={props.isLoading}
            spinner={
                <Loader active color="black" type='ball-grid-pulse' />
            }
            text={getTranslation(props.i18n, 'loading')}
            className={cx(
                loginStyles.loginBoxContainer
            )}
        >
            <div className={styles.loginBox}>
                <Logo />
                <h4 className="mb-0">
                    <span className="d-block">
                        {getTranslation(props.i18n, 'welcomeBack')}
                    </span>
                    <span> {getTranslation(props.i18n, 'pleaseSignIn')}. </span>
                </h4>
                <div className={commonStyles.invisibleDivider}> </div>
                <LoginForm />
                <div className={commonStyles.invisibleDivider}> </div>
            </div>
        </LoadingOverlay>
    );
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        isLoading: state.isLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginBox);
