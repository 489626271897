// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ex7A-D612ZIaoNSOZ15Zgg\\=\\={position:absolute;top:0px;bottom:0;width:100%;left:0;overflow:hidden}.QyEU4hdsM2dlpuM8hm8zpw\\=\\={display:flex;justify-content:center;align-items:center;background-color:#fff;height:60%;width:100%;border-radius:20px}", "",{"version":3,"sources":["webpack://Skysnap.WebPortal.Client/Login/sass/login.scss"],"names":[],"mappings":"AAAA,4BACI,iBAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,MAAA,CACA,eAAA,CAGJ,4BACI,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA","sourcesContent":[".loginContainer {\r\n    position: absolute;\r\n    top: 0px;\r\n    bottom: 0;\r\n    width: 100%;\r\n    left: 0;\r\n    overflow: hidden;\r\n}\r\n\r\n.loginBoxContainer {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: white;\r\n    height: 60%;\r\n    width: 100%;\r\n    border-radius: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": "ex7A-D612ZIaoNSOZ15Zgg==",
	"loginBoxContainer": "QyEU4hdsM2dlpuM8hm8zpw=="
};
export default ___CSS_LOADER_EXPORT___;
